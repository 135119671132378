<template>
  <div class="customer-accounts-rider-group-sidebar">
    <div class="customer-accounts-rider-group-sidebar--content">
      <div v-if="mode === 'delete'">
        <p>Are you sure you want to delete this rider group?</p>
      </div>
      <v-form v-else>
        <CRInput
          v-model="customerAccountName"
          label="Customer Account"
          placeholder="Customer Account"
          disabled
        />
        <CRInput
          v-model="riderGroupName"
          label="Rider Group Name"
          placeholder="Rider Group Name"
          :disabled="isDefaultGroup"
        />
        <CRSelect
          v-model="selectedContractIds"
          label="Contracts"
          placeholder="Select contracts"
          item-text="contractName"
          item-value="id"
          multiple
          :items="contracts"
        />
        <CRMultiTextInput
          v-model="domains"
          label="Domains"
          placeholder="Type domain name"
          :rules="[
            (val) => (val[0] !== '@' ? 'Domain must start with @' : true),
            (val) => (!val.includes('.') ? 'Domain must contain a .' : true),
          ]"
        />
      </v-form>
    </div>
    <div class="cr-sidebar-dialog--button-spacer" />
    <div>
      <CRButton
        id="customer-accounts-rider-group-save-btn"
        :loading="loading || submitting"
        class="customer-accounts-rider-group-sidebar--action-btn"
        :color="mode === 'delete' ? 'red' : 'primary'"
        @click="submit"
      >
        {{ mode === 'delete' ? 'Delete' : 'Save' }}
      </CRButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import customerAccounts from '@/services/customerAccounts'
import CRMultiTextInput from './CRMultiTextInput.vue'

export default {
  components: { CRMultiTextInput },
  props: {
    customerAccountId: {
      type: Number,
      default: null,
    },
    customerAccountName: {
      type: String,
      default: null,
    },
    mode: {
      type: String,
      default: 'edit',
    },
    riderGroup: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    customerAccount() {
      return this.$store.getters['customerAccounts/getCustomerAccountDetail']
    },
  },
  data() {
    return {
      riderGroupName: null,
      selectedContractIds: [],
      contracts: [],
      domains: [],
      isDefaultGroup: false,
      loading: false,
      submitting: false,
    }
  },
  async mounted() {
    const contractsResponse = await customerAccounts.getContracts(
      this.customerAccount.id
    )
    this.contracts = contractsResponse.data
    if (this.mode === 'edit') {
      this.riderGroupName = this.riderGroup.name
      this.isDefaultGroup = this.riderGroup.isDefaultGroup
      this.selectedContractIds = this.riderGroup.contracts.map((c) => c.id)
      this.domains = this.riderGroup.domains
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    async submit() {
      this.submitting = true

      const payload = {
        customerAccountId: this.customerAccountId,
        name: this.riderGroupName,
        contracts: this.selectedContractIds,
        domains: this.domains,
      }

      try {
        if (this.mode === 'add') {
          const createRiderGroupResponse = await customerAccounts.createRiderGroup(
            payload
          )
        } else if (this.mode === 'edit') {
          payload.riderGroupId = this.riderGroup.id
          const updateRiderGroupResponse = await customerAccounts.updateRiderGroup(
            payload
          )
        } else if (this.mode === 'delete') {
          const deleteRiderGroupResponse = await customerAccounts.deleteRiderGroup(
            this.customerAccountId,
            this.riderGroup.id
          )
        }
        EventBus.$emit('global-table-view-refresh')
        let message = ''
        switch (this.mode) {
          case 'add':
            message = 'Rider Group successfully created.'
            break
          case 'edit':
            message = 'Rider Group successfully updated.'
            break
          case 'delete':
            message = 'Rider Group successfully deleted.'
            break
        }
        this.$nextTick(() => {
          this.showAlert({
            type: 'success',
            message: message,
          })
        })
      } catch (e) {
        console.error(e)
        this.showAlert({
          type: 'error',
          message: 'Error saving rider group, please try again.',
        })
        this.submitting = false
        return
      }

      this.submitting = false
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.customer-accounts-rider-group-sidebar {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0;
  }
}
</style>
